body {
  overscroll-behavior: none;
  margin: 0;
  font-family: "Outfit",
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI Semibold",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Helvetica, Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-weight: 200;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $sand !important; 
}

.bg-sand {
  background-color: $sand !important; 
}

.bg-green {
  background-color: $green !important; 
}

.navbar {
  padding: 1rem;
  background-color: $sand !important;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-nav > * {
  margin: 0px 1rem;
}

.rsvp {
  align-self: center !important; 

  @media (max-width: $screen-sm) {
    margin: 8px 1rem; 
    align-self: start !important;
  }
}

.nav-link {
  color: $marron;
  text-wrap: nowrap;
  font-size: 1.125rem;

  &:hover {
    color: #CE4600;
    font-weight: 200;
  }

  &.active {
    color: #CE4600 !important;
    font-weight: 500;
  }
}

.offcanvas-backdrop.show {
  opacity: 0;
}

.playball {
  font-family: "Playball", 
    "Outfit",
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI Semibold",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue", Helvetica, Arial, sans-serif,
    // Emoji fonts
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.green {
  color: $green !important;
}

.blue {
  color: $blue !important;
}

.orange {
  color: $orange !important;
}

.blanc {
  color: $blanc !important;
}

.sand {
  color: $sand !important;
}

.marron {
  color: $marron !important;
}

.sun {
  color: $sun !important;
}

.dune {
  color: $dune !important;
}

footer {
  margin-top: auto;
  z-index: 4
}

.font-size-2-rem {
  font-size: 2rem;
}