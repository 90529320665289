#travel {
  #map {
    width: 100%;
    min-height: 70vh;
  }

  h2 {
    font-size: 2rem;
    color: #CE4600;
    margin-bottom: 1.5rem;
    line-height: 2.5rem;
  }

  p, li { 
    color: $green;
    font-weight: 200;
  }

  .coming {
    ul {
      li {
        a {
          color: $marron;
        }
      }
    }
    
    .card {
      border-color: $green;
  
      .card-header {
        color: $orange;
        font-weight: 500;
        background-color: $sand;
        border-color: $green;
      }
    }
  }
 

  .accomodation {
    .card-container {
      margin-top: 2rem;
      
      button {
        display: none; 
        
        @media (min-width: $screen-lg) {
          display: block;
        }
      }

      .card {
        flex: 1;
        background-color: $sand; 
        border: none;
        border-radius: 0px;
        margin: 1rem 0;

        @media (min-width: $screen-lg) {
          margin: 2rem 0;
        }

        &.main {
          z-index: 2;
          background-color: $dune;

          @media (min-width: $screen-lg) {
            scale: 1.1;
          }
        }

        h3 {
          color: $green;
          font-size: 1.5rem;
          margin-bottom: 1rem;
          text-align: center;
        }

        p {
          color: $marron;
          font-size: 1rem;
          font-weight: 300;
        }

        ul {
          a {
            color: $green;
          }
        }
      }
    }
  }

  .interests {
    .points-container {
      margin-top: 2rem; 
    }

    .card {
      border-color: $green;
    }

    .card-title {
      color: $marron;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    .box-shadow {
      box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05)
    }

    .card-img {
      height: 100%;
      border-radius: 0 3px 3px 0;
    }
    
    .flex-auto {
      flex: 0 0 auto;
    }
  }
}