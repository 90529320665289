#registry {
  h2 {
    color: $orange;
    margin: 1rem 0;
    
    @media (min-width: $screen-md) {
      margin: 2rem 0;
    }
  }

  img {
    border: 5px solid $white;
    border-top-left-radius: 200px 200px;
    border-top-right-radius: 200px 200px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}