#details {
  h2 {
    color: $orange; 
  }

  .accordion-item {
    border: none;
    border-bottom: 1px solid $green; 
  }

  .accordion-button {
    background-color: $sand;
    color: $green;
    font-weight: 500;

    &:focus {
      box-shadow: none;
    }

   &:not(.collapsed) {
    color: $orange;
   }
  }

  .accordion-body {
    border-top: 1px solid lightgray; 
    font-weight: 200;
    color: $green;

    li {
      font-weight: 300;
    }
  }

  #moving-kiwi-container {
    height: 100px; 
    margin-bottom: 2rem; 
    position: relative;

    @keyframes moveAndRotate {
      0% { transform: translateX(0) rotate(0deg); }
      100% { transform: translateX(100vw) rotate(980deg); }
    }

    img {
      position: absolute; 
      transform: rotateY(-180deg);
      bottom: -22px;
      height: 100px;
      width: 100px;
      animation: moveAndRotate 20s linear infinite;

      @media (max-width: $screen-md) {
        animation: moveAndRotate 10s linear infinite;
       }
    }
  }
}