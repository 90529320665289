.cover-container {
  width: 100%;
  margin: auto; 
}

.save_the_date {
  width: 100%;
  max-height: calc(100vh - $navbar-height);
  margin: auto;
}

#planning {
	padding: 7em 0;
	clear: both;
	@media screen and (max-width: 768px) {
		padding: 3em 0;
	}

  h2 {
    color: $orange;
    margin-bottom: 3.5rem;
  }
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  &:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: white;
  left: 50%;
  margin-left: 0px;
  @media screen and (max-width: 480px) {
    margin-left: -64px;
  }
}
> li{
  margin-bottom: 40px;
  position: relative;
  &:before, &:after{
    content: " ";
    display: table;
  }
  &:after{
    clear: both;
  }
  > .timeline-panel {
    background-color: $gray;
    width: 40%;
    float: left;
    border: 1px solid #d4d4d4;
    padding: 30px;
    position: relative;
    @include border-radius(4px);
    &:before {
      position: absolute;
      top: 80px;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      border-left: 15px solid #d4d4d4;
      border-right: 0 solid #d4d4d4;
      border-bottom: 15px solid transparent;
      content: " ";
    }
    &:after {
      position: absolute;
      top: 81px;
      right: -14px;
      display: inline-block;
      border-top: 14px solid transparent;
      border-left: 14px solid #F8F9FA;
      border-right: 0 solid #F8F9FA;
      border-bottom: 14px solid transparent;
      content: " ";
    }
    @media screen and (max-width: 480px) {
      width: 75% !important;
      &:before{
        top: 30px;
      }
      &:after{
        top: 31px;
      }
    }
  }
  > .timeline-badge {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    color: #fff;
    width: 160px;
    height: 160px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -80px;
    background-color: $sand;
    z-index: 100;
    border: 5px solid $white; 
    @include border-radius(50%);
    @media screen and (max-width: 480px) {
      width: 60px;
      height: 60px;
      margin-left: -20px !important;
    }
  }
  &.timeline-inverted {
    > .timeline-panel {
      float: right;      
      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }
      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }
}
}

.timeline-title {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 500;
  color:#CE4600;
}

.timeline-day {
  position: absolute;
  top: -25px;

  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 200;
  color:#CE4600;
}

.subtitle {
  display: block;
  color: $marron;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
  color: #0B5A81;
  font-weight: 200;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: $screen-md) {
ul.timeline:before {
  left: 90px;
}

ul.timeline > li > .timeline-panel {
  width: calc(100% - 200px);
  width: -moz-calc(100% - 200px);
  width: -webkit-calc(100% - 200px);
  // width: 40%;
}

ul.timeline > li > .timeline-badge {
  left: 15px;
  margin-left: 0;
  top: 16px;
}

ul.timeline > li > .timeline-panel {
  float: right;
}

ul.timeline > li > .timeline-panel:before {
   border-left-width: 0;
   border-right-width: 15px;
   left: -15px;
   right: auto;
}

ul.timeline > li > .timeline-panel:after {
   border-left-width: 0;
   border-right-width: 14px;
   left: -14px;
   right: auto;
}
}
