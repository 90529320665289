#home {  
  h2 {
    font-size: 2rem;
    color: #CE4600;
    margin-bottom: 1.5rem;
    line-height: 2.5rem;
  }
  
  p {
    color: $green;
  }

  .cover-container {
    height: calc(100vh - $navbar-height);

    @media (max-width: $screen-md) {
      height: auto;
     }
  }

  .planning-home {
    .card-body {
      h5 {
        text-transform: uppercase;
      }
    }
  }

  .invitation-card { 
    h1 {
      margin-bottom: 1rem;
      font-weight: 300;
      font-size: 3rem;
      color: $green;

      @media (max-width: $screen-md) {
        font-size: 2.5rem;
      }
    }

    p {
      color: $green; 
      font-size: 1.375rem;
      font-weight: 200;
      margin-bottom: 2.5rem;

      @media (max-width: $screen-md) {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        text-align: center;
      }
    }

    h2 {
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
      color: $orange;

      @media (max-width: $screen-md) {
        margin-bottom: 0rem;
       }
    }
  }

  .invitation {
    height: 100%;
    width: 100%;
    padding-bottom: 100px;

    @media (max-width: $screen-xs) {
      padding-bottom: 75px;
    }
  }

  .engagement-image {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid white;

    @media (max-width: $screen-xs) {
      width: 150px;
      height: 150px;
    }
  }
}

section {
  min-height: 50vh;

  h2 {
    font-size: 3rem;
    line-height: 3.75rem;
  }
}

.answer {
  h3 {
    font-size: 3rem;
    line-height: 3.125rem;
    font-weight: 300;
    color: $blue;
    margin-bottom: 2rem;

    @media (max-width: $screen-sm) {
      font-size: 2rem;
    }
  }
}