#upcoming {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: $sand;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem;

  p {
    font-size: 1.125rem;
  }
}